import React from "react";
import Layout from "../components/Layout";
import PrivacyStyles from "./privacy.module.scss";

function privacy() {
  return (
    <Layout>
      <div className={PrivacyStyles.container}>
        <h1>Privacy Notice</h1>
        <ul>
          <li>
            <h3>Our Commitment To Privacy</h3>
            <p>
              Hashout is committed to the information privacy of our website
              visitors. The guidelines explain our practices and the choices
              visitors can make about the way information is collected and used.{" "}
            </p>
          </li>
          <li>
            <h3>The Information We Collect</h3>
            <p>
              On some pages, visitors can make requests, and register to as part
              of inquiries. The types of personal information collected at these
              pages are: Name, Address, Email and Phone Number.
            </p>
          </li>
          <li>
            <h3>The Way We Use Information</h3>
            <p>
              We use return email addresses to answer the email we receive and
              this info will not be used for any other purposes and or shared
              with third parties.
            </p>
            <p>
              We use non-identifying and aggregate information to better design
              our website but we would not disclose anything that could be used
              to identify those individuals.
            </p>
          </li>
          <li>
            <h3>Our Commitment To Data Security</h3>
            <p>
              To prevent unauthorized access, maintain data accuracy, and ensure
              the correct use of information, we have put in place appropriate
              procedures to safeguard and secure the information we collect
              online.
            </p>
            <p>
              The data is stored exclusively in secure hosting facilities like
              google cloud. We may not have data protection laws as protective
              as those in your country of residence, our collection, storage,
              and use of your personal information will at all times be governed
              by this Privacy Policy.
            </p>
          </li>
          <li>
            <h3>How To Access, Correct, or Delete Your Information</h3>
            <p>
              You can contact us to inquire about your personally identifiable
              information that we collect online and maintain. We use this
              procedure to better safeguard your information.
            </p>
            <p>
              You can correct factual errors in your personally identifiable
              information or delete your information by sending us a request.
            </p>
            <p>
              To protect your privacy and security, we will also take reasonable
              steps to verify your identity before granting access or making
              corrections.
            </p>
            <p>
              If you have any questions, please contact us at:
              <br />
              <br />
              5725 Center Square Dr,
              <br />
              Suite 200B,
              <br />
              Centreville Va 20120
              <br />
              Email:{" "}
              <a href="mailto:contact@hashouttech.com">
                contact@hashouttech.com
              </a>
            </p>
          </li>
        </ul>
      </div>
    </Layout>
  );
}

export default privacy;
